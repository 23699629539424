/* Ensure the body and html use the full viewport height and have no margin */
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: Arial, sans-serif;
  background: linear-gradient(235deg, #6a11cb 0%, #2575fc 100%);
  color: white;
}

.popup {
  position: fixed;
  top: 0;
  width: 50%;
  background-color: black;
  color: white;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}

.close-popup-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
}

.popup p {
  margin: 0;
}

/* Ensure the root div uses the full viewport height */
#root {
  height: 100%;
}

/* Ensure the App component uses the full viewport height and centers content */
.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}

/* Ensure the header stretches across the full width */
.App-header {
  width: 100%;
  padding: 0;
}

/* Ensure the nav uses the full width and centers content */
nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.logo-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Add margin for spacing */
}

.logo-image {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.logo-text {
  font-size: 1.5rem;
  font-weight: bold;
}

.download-button {
  background-color: #fff;
  color: #6a11cb;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px; /* Add margin for spacing */
}

.main-content {
  text-align: center;
  margin-top: 30px;
}

.main-content h1 {
  font-size: 3rem;
  margin-top: 100px;
}

.main-content p {
  font-size: 1.2rem;
  margin-top: 70px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  flex-wrap: wrap; /* Allow buttons to wrap on smaller screens */
}

.clean-button {
  background-color: #fff;
  color: #6a11cb;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;
  width: auto; /* Adjust width for responsiveness */
}

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Ensure the main section is centered and uses the full width */
main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 20px;
  box-sizing: border-box;
}

.info-blocks {
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
}

.info-block {
  background: rgba(30, 30, 30, 0.6);
  padding: 20px;
  border-radius: 10px;
  max-width: 300px;
  text-align: left;
  margin: 10px;
}

.info-block h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.info-block p {
  font-size: 1.2rem;
  margin-top: 30px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .popup {
    width: 90%;
    left: 5%;
  }

  nav {
    flex-direction: column;
    align-items: center; /* Center items on smaller screens */
  }

  .main-content h1 {
    font-size: 2rem;
  }

  .main-content p {
    font-size: 1rem;
  }

  .clean-button {
    width: auto;
    margin: 10px;
  }

  .info-blocks {
    flex-direction: column;
    align-items: center;
  }

  .info-block {
    width: 90%;
    max-width: none;
  }
}

@media (max-width: 480px) {
  .main-content {
    margin-top: 20px;
  }

  .main-content h1 {
    font-size: 1.5rem;
  }

  .main-content p {
    font-size: 0.9rem;
  }

  .clean-button {
    width: auto;
    margin: 10px;
  }

  .info-blocks {
    flex-direction: column;
    align-items: center;
  }

  .info-block {
    width: 95%;
    margin-bottom: 20px;
  }
}

